import styles from './SectionView.module.scss';
import { Section } from '../../../types.js';
import useBreakpoints from '../../../services/useBreakpoints.ts';
import { CommentsStateForSection } from './publish/commentsSidebar/useComments/useComments.ts';
import { CommentsFooterButton } from './publish/commentsSidebar/CommentsFooterButton.tsx';
import React, { RefObject } from 'react';
import { getSpacing } from '../editor/blockPreprocessing/addSpacing.ts';
import SidebarTitle from './SidebarTitle.tsx';
import BlockLayout from '../../../design-system/zeck/BlockLayout.tsx';
import getDefaultSectionViewContent from '../getDefaultSectionViewContent.ts';
import readTimeForSection from '../../../readTime/readTimeForSection.ts';
import { renderGroupListRenderables } from '../editor/blockPreprocessing/groupListRenderables.tsx';
import SectionViewLayout from './SectionViewLayout.tsx';
import TitleCard from './TitleCard.tsx';
import CoverHeadlineCard from './CoverHeadlineCard.tsx';
import useHideStickyTitle from './useHideStickyTitle.ts';
import mergeRefs from '../../../junkDrawer/mergeRefs.ts';
import {
  ZeckFinalizeVoteCapability,
  ZeckPrevoteCapability,
} from '../voting/VoteCapability.js';
import BlockViewable from './BlockViewable.tsx';
import Linkable from 'editor-content/html/Linkable.ts';
import { useMediaQuery } from 'react-responsive';

type SectionViewProps = {
  section: Pick<
    Section,
    | 'title'
    | 'headline'
    | 'body'
    | 'supplemental'
    | 'coverImageEnabled'
    | 'coverImageId'
  >;
  commentsStateForSection?: CommentsStateForSection;
  linkables: Linkable[];
  preview?: boolean;
  scrollContainerRef: RefObject<HTMLElement>;
  zeckPrevoteCapability: ZeckPrevoteCapability | null;
  zeckFinalizeVoteCapability: ZeckFinalizeVoteCapability | null;
  onFilePreview: (pdfUrl: string) => void;
};

const SectionView = React.forwardRef<HTMLDivElement, SectionViewProps>(
  function SectionView(
    {
      section,
      commentsStateForSection,
      linkables,
      preview,
      scrollContainerRef,
      zeckPrevoteCapability,
      zeckFinalizeVoteCapability,
      onFilePreview,
    },
    forwardedRef,
  ) {
    const { isMobile } = useBreakpoints();

    const isTooNarrowForCommentsToBeOpen = useMediaQuery({
      maxWidth: 1380,
    });

    const { title, headline, body } = getDefaultSectionViewContent(section);

    const { setElementAt, targetElementRef } = useHideStickyTitle(body);

    const hideSidebar = !!(
      isMobile ||
      (isTooNarrowForCommentsToBeOpen && commentsStateForSection?.sidebarOpen)
    );

    const unselectComment = () => {
      if (
        commentsStateForSection &&
        commentsStateForSection.getSelectedCommentThreadId()
      ) {
        commentsStateForSection.unselectComment();
      }
    };

    return (
      <SectionViewLayout
        ref={forwardedRef}
        mainContentRef={commentsStateForSection?.setSectionContentRef}
        hideSidebar={hideSidebar}
        {...{
          headingContent: (
            <>
              <TitleCard
                title={title}
                readTime={
                  section.supplemental ? null : readTimeForSection(section)
                }
                commentsState={commentsStateForSection}
              />
              {((section.coverImageEnabled && section.coverImageId) ||
                headline.length > 0) && (
                <CoverHeadlineCard
                  linkables={linkables}
                  content={headline}
                  isMobile={hideSidebar}
                  coverImageId={
                    section.coverImageEnabled ? section.coverImageId : null
                  }
                />
              )}
            </>
          ),
          sidebarContent: !hideSidebar && (
            <div>
              <SidebarTitle
                className={styles.sectionView__stickyTitle}
                ref={mergeRefs([targetElementRef])}
                commentsState={commentsStateForSection}
                title={title}
                readTime={
                  section.supplemental ? null : readTimeForSection(section)
                }
              />
            </div>
          ),
        }}
      >
        <BlockLayout onClick={unselectComment}>
          {renderGroupListRenderables(
            body.map((block) => ({
              data: { block },
            })),
            ({ data: { block }, i, spacing, groupedRenderableType }) => (
              <BlockViewable
                preview={preview}
                commentsState={commentsStateForSection}
                key={block.type === 'image' ? block.guid : i}
                className={
                  groupedRenderableType === 'item' ? getSpacing(spacing) : ''
                }
                ref={setElementAt(i)}
                scrollViewContainer={scrollContainerRef}
                block={block}
                linkables={linkables}
                zeckPrevoteCapability={zeckPrevoteCapability}
                zeckFinalizeVoteCapability={zeckFinalizeVoteCapability}
                onFilePreview={onFilePreview}
              />
            ),
          )}

          {commentsStateForSection && (
            <div className={styles.sectionView__comments}>
              <CommentsFooterButton commentsState={commentsStateForSection} />
            </div>
          )}
        </BlockLayout>
      </SectionViewLayout>
    );
  },
);
export default SectionView;
